<template>
  <div class="Export_Page">
    <v-app-bar dense fixed app color="blue darken-3" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>โพยตีออก</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card class="mx-auto ml-5 mr-5 mt-5" color="grey lighten-5">
      <v-toolbar flat color="blue-grey lighten-5">
        <!-- <v-btn rounded color="info ml-2" dark @click="printSection">
          <v-icon left>
            mdi-printer
          </v-icon>
          พิมพ์
        </v-btn>
        <v-divider class="mx-4 info" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-btn rounded outlined color="pink">
          <v-icon left>
            mdi-format-align-center
          </v-icon>
          ดูโพยทั้งหมด
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-layout row>
          <v-col xl="8" lg="8" md="8" cols="12">
            <v-row>
              <v-col lg="3" md="6" cols="12" class="mt-2 mb-0 pt-0 pb-0">
                <v-card elevation="2" class="rounded-lg">
                  <v-card-text
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <strong>ทั้งหมด (ใบ)</strong
                      ><br />
                    </div>
                    <v-avatar color="indigo" size="36">
                      <span class="white--text">{{page_all}}</span>
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col lg="3" md="6" cols="12" class="mt-2 mb-0 pt-0 pb-0">
                <v-card elevation="2" class="rounded-lg">
                  <v-card-text
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <strong>รับแล้ว</strong
                      ><br />
                    </div>
                    <v-avatar color="green" size="36">
                      <span class="white--text">{{page_accept}}</span>
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col lg="3" md="6" cols="12" class="mt-2 mb-0 pt-0 pb-0">
                <v-card elevation="2" class="rounded-lg">
                  <v-card-text
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <strong>รอรับ</strong
                      ><br />
                    </div>
                    <v-avatar color="amber darken-2" size="36">
                      <span class="white--text">{{page_waiting}}</span>
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col lg="3" md="6" cols="12" class="mt-2 mb-0 pt-0 pb-0">
                <v-card elevation="2" class="rounded-lg">
                  <v-card-text
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <strong>ตีกลับ</strong
                      ><br />
                    </div>
                    <v-avatar color="red" size="36">
                      <span class="white--text">{{page_reject}}</span>
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- <v-col
                lg="3"
                md="6"
                cols="12"
                v-for="(item, index) in exp_data_items"
                :key="index"
                class="mt-2 mb-0 pt-0 pb-0"
              >
                <v-card elevation="2" class="rounded-lg">
                  <v-card-text
                    class="d-flex justify-space-between align-center"
                  >
                    <div>
                      <strong>{{ item.text }}</strong
                      ><br />
                      <small>{{ item.description }}</small>
                    </div>
                    <v-avatar :color="item.color" size="36">
                      <span class="white--text">{{ item.value }}</span>
                    </v-avatar>
                  </v-card-text>
                </v-card>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" class="mt-2 mb-0 pt-3 pb-0">
                <ExportPagesDatatable></ExportPagesDatatable>
              </v-col>
            </v-row>
          </v-col>
          <!-- activity -->
          <v-col xl="4" lg="4" md="4" cols="12" class="scroll">
            <v-timeline align-top dense>
              <v-timeline-item color="pink" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>5pm</strong>
                  </v-col>
                  <v-col>
                    <strong>New Icon</strong>
                    <div class="text-caption">
                      Mobile App
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="teal lighten-3" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>3-4pm</strong>
                  </v-col>
                  <v-col>
                    <strong>Design Stand Up</strong>
                    <div class="font_s mb-2">
                      Hangouts
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="pink" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>12pm</strong>
                  </v-col>
                  <v-col>
                    <strong>Lunch break</strong>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item color="teal lighten-3" small>
                <v-row class="pt-1">
                  <v-col cols="3">
                    <strong>9-11am</strong>
                  </v-col>
                  <v-col>
                    <strong>Finish Home Screen</strong>
                    <div class="text-caption">
                      Web App
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-col>
        </v-layout>
      </v-card-text>
    </v-card>

    <!-- <v-breadcrumbs :items="bc_items" class="pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs> -->
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import ExportPagesDatatable from "@/components/Data/Export_Page/Dashboard/Datatable";
import { read } from "fs";
// import HpSplitView from "@/components/Data/HP_Number/Hp_SplitView";
// import HpViewAll from "@/components/Data/HP_Number/Hp_ViewAll";

export default {
  name: "Export_Pages",
  mixins: [globalFunctionMixin],
  components: {
    ExportPagesDatatable,
    // HpSplitView,
    // HpViewAll,
  },

  mounted() {
    this.ckLogin();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    page_all() {
      var Page = this.$store.getters["export_page/ReBtHosts"];
      var PageAllCount = 0;
      console.log(Page);
      for (let i = 0; i < Page.length; i++) {
        PageAllCount += parseInt(Page[i].W);
        PageAllCount += parseInt(Page[i].A);
        PageAllCount += parseInt(Page[i].R);
      }
      return PageAllCount;
    },
    page_accept() {
      var Page = this.$store.getters["export_page/ReBtHosts"];
      var PageAcceptCount = 0;
      for (let i = 0; i < Page.length; i++) {
        PageAcceptCount += parseInt(Page[i].A);
      }
      return PageAcceptCount;
    },
    page_waiting() {
      var Page = this.$store.getters["export_page/ReBtHosts"];
      var PageWaintingCount = 0;
      for (let i = 0; i < Page.length; i++) {
        PageWaintingCount += parseInt(Page[i].W);
      }
      return PageWaintingCount;
    },
    page_reject() {
      var Page = this.$store.getters["export_page/ReBtHosts"];
      var PageRejectCount = 0;
      for (let i = 0; i < Page.length; i++) {
        PageRejectCount += parseInt(Page[i].R);
      }
      return PageRejectCount;
    },
  },
  data: () => ({
    exp_data_items: [
      {
        text: "ทั้งหมด (ใบ)",
        // description: "ยอดรวม บาท",
        color: "indigo",
        value: "99",
      },
      {
        text: "รับแล้ว",
        description: "",
        color: "green",
        value: 50,
      },
      {
        text: "รอรับ",
        description: "",
        color: "amber darken-2",
        value: 0,
      },
      {
        text: "ตีกลับ",
        description: "",
        color: "red",
        value: 0,
      },

      //   {
      //     text: "ยอดรวมส่งออก",
      //     description: "",
      //     value: 0,
      //   },
      //   {
      //     text: "กำไรสูงสุด",
      //     description: "เลข .. จาก เจ้า 1 เจ้า 2 โพยไหนบ้าง",
      //     value: 0,
      //   },
    ],
  }),
};
</script>

<style scoped>
.scroll {
  flex-grow: 1;
  overflow-y: scroll;
  height: 80vh;
}
.font_s {
  font-size: 0.8em !important;
}
</style>
