<template>
  <div
    style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;"
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="page_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      height="57vh"
      fixed-header
    >
      <template v-slot:item="{ item }">
        <tr
          @contextmenu="popup_menu($event, item)"
          @click="row_click(item)"
          :class="{ 'blue lighten-5': item.id === selectedId }"
          class="font-weight-black"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->
          <td :class="id_column">{{ item.id }}</td>
          <!-- <td>{{ item.Nickname }}</td> -->
          <td v-if="item.Status == 1" class="red--text">
            {{ item.Name }}
          </td>
          <td v-if="item.Status == 0">{{ item.Name }}</td>

          <td class="teal--text" align="end">
            {{ item.W }}
          </td>
          <td class="purple--text" align="end">
            {{ item.A }}
          </td>
          <td align="end" class="red--text">{{ item.R }}</td>

          <td align="end">{{ item.PSUM}}</td>
          <td align="end">
            {{ item.LSUM }}
          </td>

          <td v-if="item.Status == 1" class="red--text" align="center">
            ระงับ
          </td>
          <td v-if="item.Status == 0" class="green--text" align="center">
            ใช้งาน
          </td>
        </tr>
      </template>
    </v-data-table>
    <PopupMenu ref="PopupMenu1" />
  </div>
</template>

<script>
import PopupMenu from "@/components/Data/Export_Page/Dashboard/Popup_Menu";

export default {
  name: "CurrentBet_Datatable",

  components: {
    PopupMenu,
  },
  created() {
    //โหลด ตาราง
    //this.LineID = this.$route.query.line_id;
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    page_data() {
      return this.$store.getters["export_page/ReBtHosts"];
    },
    page_count() {
      return this.$store.getters["export_page/ReBtHosts"].length;
    },
  },
  methods: {
    //   SetLineID(LineID){
    //       this.LineID = LineID;
    //   },
    async fetch_table() {
      console.log("fetch_table");
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        ListType: "genaral_list",
      };
      await this.$store.dispatch("export_page/get_list", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    rowClick: function(item, row) {
      row.select(true);
      this.selectedId = item.id;
      console.log(item.id);
    },
    row_click: function(item) {
      this.selectedId = item.id;
      this.$set(item, "selected", true);
      //   console.log(item.id);
    },
    popup_menu: function(e, item) {
      e.preventDefault();

      this.$refs.PopupMenu1.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        // this.$refs.PopupMenu1.SetLineID(this.LineID);
        this.$refs.PopupMenu1.Show(item.id);
        this.selectedId = item.id;
        //console.log(item);
      });
    },
    printSection() {
      this.$htmlToPaper("printSection");
    },
  },
  data: () => ({
    //LineID: "",

    selectedId: -1,

    // // right click
    // showMenu: false,
    // x: 0,
    // y: 0,

    headers: [
      {
        text: "#",
        value: "LineID",
        width: "1%",
      },
      {
        text: "เจ้ามือ",
        value: "Nickname",
        align: "start",
        width: "12%",
        class: "brown lighten-5",
      },
      {
        text: "รอรับ",
        value: "PhoneNumber1",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "รับ",
        value: "WaittingPage",
        align: "end",
        width: "9%",
        class: "brown lighten-5",
        sortable: true,
      },
      {
        text: "ตีกลับ",
        value: "PhoneNumber1",
        align: "end",
        width: "10%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดรวม",
        value: "PriceSum",
        align: "end",
        width: "13%",
        class: "brown lighten-5",
      },
      {
        text: "ยอดลอย",
        value: "PriceLoy",
        align: "end",
        width: "13%",
        class: "brown lighten-5",
      },
      {
        text: "สถานะ",
        value: "Status",
        align: "center",
        width: "12%",
        class: "brown lighten-5",
      },
    ],
    id_column: "id_column_class",
  }),
};
</script>

<style scoped>
::v-deep .id_column_class {
  background-color: rgb(57, 137, 198) !important;
  color: whitesmoke;
  position: sticky !important;
  width: 3%;
  left: 0;
}

::v-deep table thead th:first-child {
  position: sticky !important;
  left: 0 !important;
  z-index: 3 !important;
  background: rgb(57, 137, 198) !important;
  color: whitesmoke !important;
  padding-top: 15px !important;
}

::v-deep table thead th {
  padding: 3px;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 25vw;
  background: rgb(255, 255, 255);
}

::v-deep table {
  background-color: #f5f5f5;
  font-size: 1.8rem !important;
}

::v-deep table tbody tr td {
  font-size: 0.8em !important;
  color: rgb(88, 88, 88);
  font-weight: normal;
}

@media only screen and (min-width: 850px) {
  ::v-deep table tbody tr td {
    font-size: 0.8em !important;
    color: rgb(88, 88, 88);
    font-weight: bold;
  }
}
</style>
